p, span, h1, h2, h3, h4, h5,  h6 {
  line-height: 1.3 !Important;
}

@font-face {
  font-family: 'Avenir black';
  src: url('./assets/fonts/Avenir-Black.otf');
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('./assets/fonts/Avenir-Medium.otf');
}

@font-face {
  font-family: 'Avenir heavy';
  src: url('./assets/fonts/Avenir-Heavy.otf');
}

@font-face {
  font-family:'Poppins Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family:'Poppins Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family:'Poppins Light';
  src: url('./assets/fonts/Poppins-Light.ttf');
}

